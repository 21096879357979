/// <reference path="../../../node_modules/pixi.js/pixi.js.d.ts" />
import * as React from "react"
import styles from "../../styles/styles.module.scss"
import scriptLoader, { Dependency } from "../../utils/scriptLoader"

export interface IExperimentApp {
  canvas: HTMLCanvasElement
  start: () => void
  stop: () => void
}

export interface ExperimentProps {
  dependencies?: Dependency[] | Dependency
}

export default abstract class Experiment extends React.Component<ExperimentProps, any> {
  protected dependeciesLoaded: number
  canvas: HTMLCanvasElement
  class: new(canvas:HTMLCanvasElement) => IExperimentApp
  app: IExperimentApp

  constructor(props: ExperimentProps) {
    super(props)
    this.dependeciesLoaded = 0
  }

  start = () => {
    this.app = new this.class(this.canvas)
    this.app.start()
  }

  componentWillUnmount() {
    this.app.stop()
  }

  dependencyLoaded() {
    this.dependeciesLoaded++
    if(this.dependeciesLoaded >= (this.props.dependencies as Dependency[]).length - 1) {
      this.start()
    }
  }

  protected loadDependencies() {
    const dependencies = this.props.dependencies
    if(dependencies) {
      if(Array.isArray(dependencies)) {
        dependencies.map(dependency => scriptLoader(dependency, this.dependencyLoaded))
      } else {
        scriptLoader(dependencies, this.start)
      }
    } else {
      this.start()
    }
  }

  componentDidMount() {
    this.loadDependencies()
  }

  viewRef = (element: HTMLCanvasElement) => {
    this.canvas = element;
  }

  render() {
    return <>
      <canvas className={styles.fullSizeBackground} ref={this.viewRef} />
    </>
  }
}

export const pixiDependency = {
    url: 'https://cdnjs.cloudflare.com/ajax/libs/pixi.js/5.0.2/pixi.js',
    id: 'pixi.js',
}

export const threeDependency = {
  url: 'https://cdnjs.cloudflare.com/ajax/libs/three.js/104/three.min.js',
  id: 'three.js',
}


