import * as React from "react"
import * as styles from './AmazonPhotos.module.scss';

import { getAmazonPhotos } from "./AmazonPhotos"

interface AmazonPhotosProps {
  groupShareToken: string
}

enum Phase {
  LOADING = 0,
  ERROR = 1,
  LOADED = 2,
}
interface AmazonPhotosState {
  phase: Phase
  images?: string[]
  currentIndex: number
  previoustIndex?: number
}

class AmazonPhotosGallery extends React.Component<AmazonPhotosProps, AmazonPhotosState> {
  intervalId: number
  constructor(props: AmazonPhotosProps) {
    super(props)
    this.state = { phase: Phase.LOADING, currentIndex: 0 }
  }

  componentDidMount() {
    const {groupShareToken} = this.props
    const maxWidth = Math.max(window.screen.availWidth, window.screen.availHeight);
    getAmazonPhotos(groupShareToken, maxWidth)
      .then(images => {
        this.setState({phase: Phase.LOADED, images})
        this.intervalId = window.setInterval(() => {
          const {images, currentIndex} = this.state
          const nextIntervalId = currentIndex + 1
          this.setState({previoustIndex:this.state.currentIndex ,currentIndex: images.length <= nextIntervalId ? 0 : nextIntervalId })

        }, 5000)
      })
      .catch(function (error:string) {
        this.setState({phase: Phase.ERROR})
      })
  }

  render (){
    const { currentIndex, previoustIndex, images } = this.state
    switch(this.state.phase) {
      case Phase.LOADED :
        return (<div className={styles.AmazonPhotos}><div id="photoContainer" className={styles.AmazonPhotos__images}>
          {images && images.map((img,index) => {
              const className = index === currentIndex ? styles.current : index === previoustIndex ? styles.previous : undefined
              return <img key={index} className={className} src={img}/>
            })
          }
          </div>
        </div>)
      case Phase.ERROR :
        return <div>Error...</div>
      case Phase.LOADING :
      default:
        return <div className={styles.AmazonPhotos}>Loading.....</div>
    }
  }
}
export  default AmazonPhotosGallery
