import * as React from "react"
import Experiment, { ExperimentProps } from "./Experiment"
import GhostApp from "./apps/ghost"
import styles from "../../styles/styles.module.scss"

export default class Ghost extends Experiment {
  constructor(props: ExperimentProps) {
    super(props)
    this.class = GhostApp
  }
}
