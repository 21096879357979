import axios, { AxiosResponse } from "axios"
import { AmazonDriveResponse } from "./AmazonInterfaces"

export const getAmazonPhotos = (groupShareToken: string, maxWidth:number=1920):Promise<string[]> => {
  const groupId = groupShareToken.split('.')[0]

  const amazonDriveUrl = 'https://www.amazon.com/drive/v1/search/groups/'
  const jsonUrl = `${amazonDriveUrl}${groupId}` +
    `?groupShareToken=${groupShareToken}` +
    '&asset=ALL' +
    '&limit=200' +
    '&searchContext=groups' +
    '&filters=type:(PHOTOS+OR+VIDEOS)' +
    '&resourceVersion=V2' +
    '&searchContext=groups'
  return axios.get(jsonUrl)
    .then((response: AxiosResponse) => {
      const responseRoot: AmazonDriveResponse.RootObject = response.data
      return responseRoot.data.reduce((results, imageData: AmazonDriveResponse.Datum) => {
        if (imageData.id && imageData.ownerId) {
          results.push(`https://thumbnails-photos.amazon.com/v1/thumbnail/${imageData.id}` +
            `?viewBox=${maxWidth},${Math.ceil(maxWidth*1.78)}` + // max height and max width
            `&ownerId=${imageData.ownerId}` +
            `&groupShareToken=${groupShareToken}`
          )
        }
        return results
      }, [] as string[])
    })
    .catch(function(error: string) {
      return Promise.reject('Error getting photos')
    })
}
