export interface Dependency {
  url: string
  id: string
}

enum ScriptLoadState {
  LOADING = '0',
  LOADED = '1',
}


const scriptLoader = ({id, url}: Dependency, callback?: (id: string) => void) => {
  let script: HTMLScriptElement = document.getElementById(id) as HTMLScriptElement
  if (!script) {
    script = document.createElement('script');
    script.src = url
    script.id = id
  }
  if(callback) {
    switch (script.getAttribute('data-loaded')) {
      case ScriptLoadState.LOADED:
        callback(id)
        break
      case ScriptLoadState.LOADING: // Already has event listener
        return
      default:
        const scriptLoaded = (e: Event) => {
          script.setAttribute('data-loaded', "1")
          script.removeEventListener('load', scriptLoaded)
          if (callback) callback(id)
        }
        script.setAttribute('data-loaded', ScriptLoadState.LOADING)
        script.addEventListener('load', scriptLoaded)
    }
  }
  if(!script.parentElement) {
    document.head.appendChild(script)
  }
}

export default scriptLoader
