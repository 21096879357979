import { IExperimentApp } from "../Experiment"

export abstract class AbstractCanvasApp implements IExperimentApp{
  canvas: HTMLCanvasElement
  constructor(canvas: HTMLCanvasElement) {
    this.canvas = canvas
  }
  start() {
    console.warn('AbstractCanvasApp start')
  }
  stop() {
    console.warn('AbstractCanvasApp stop')
  }
}
