import { AbstractCanvasApp } from "./AbstractCanvasApp"
import { IExperimentApp } from "../Experiment"

const PI2: number = Math.PI * 2

interface Point {
  x: number
  y: number
}

interface Vector extends Point {
  opacity: number
  distance: number
}

interface ParticleOptions extends Point {
  angle?: number
  speed?: number
  color?: string
  size?: number
  targetSize?: number
  radius?: number // TODO rename to orbit or orbitRadius
}

class Particle {
  position: Point
  shift: Point
  closest: Vector[]
  distance: number
  speed: number
  angle: number
  color: string // Why string=
  size: number
  targetSize: number
  orbit: number

  constructor(public options: ParticleOptions) {
    this.closest = []
    this.position = this.shift = { x: this.options.x, y: this.options.y }

    this.speed = this.options.speed || 0.01 + Math.random() * 0.04
    this.angle = this.options.angle || 0

    if (this.options.color) {
      const color = this.options.color
        .substring(5, this.options.color.length - 1)
        .split(",")
        .map(colorString => parseInt(colorString, 10))
      for (let i = 0; i < 3; i++) {
        color[i] = Math.round(color[i] + (Math.random() * 100) - 50)
        color[i] = Math.min(color[i], 255)
        color[i] = Math.max(color[i], 0)
      }
      this.color = color.join(",")
    }

    // Size
    this.options.size = this.options.size || 7
    this.size = 1 + Math.random() * this.options.size
    this.targetSize = this.options.targetSize || this.options.size

    this.orbit = this.options.radius * 0.5 + (this.options.radius * 0.5 * Math.random())
  }

  update = (target: Point, index: number) => {
    this.angle += this.speed

    this.shift.x += (target.x - this.shift.x) * this.speed
    this.shift.y += (target.y - this.shift.y) * this.speed

    this.position.x = this.shift.x + Math.cos(index + this.angle) * this.orbit
    this.position.y = this.shift.y + Math.sin(index + this.angle) * this.orbit

    this.size += (this.targetSize - this.size) * 0.03

    if (Math.round(this.size) === Math.round(this.targetSize)) {
      this.targetSize = 1 + Math.random() * this.options.size
    }
  }
}

export default class GhostApp extends AbstractCanvasApp implements IExperimentApp {
  options: any
  ctx: CanvasRenderingContext2D
  dpr: number  // device pixel ratio
  target: Point
  particles: Particle[]
  height: number
  width: number

  // Settings
  public count: number = 55
  speed: number = 0.8
  radius: number = 12
  size:  number = 15
  color: string = "rgb(255,255,255)"
  maxDistance: number = 200
  stopped: boolean
  start() {
    this.stopped = false
    this.init()
  }

  stop() {
    this.stopped = true
    window.removeEventListener("resize", this.updateDimensions, false)

    document.removeEventListener("touchstart", this.touchMove, false)
    document.removeEventListener("touchmove", this.touchMove, false)
    window.removeEventListener("mousemove", this.mouseMove, false)
    window.removeEventListener("mouseout", this.resetTarget, false)

  }

  init() {
    this.ctx = this.canvas.getContext("2d")

    this.dpr = window.devicePixelRatio || 1
    this.updateDimensions()
    window.addEventListener("resize", this.updateDimensions, false)
    this.resetTarget()
    const hasTouch = "ontouchstart" in window
    if (hasTouch) {
      // touch
      document.addEventListener("touchstart", this.touchMove, false)
      document.addEventListener("touchmove", this.touchMove, false)
    } else {
      // Mouse
      window.addEventListener("mousemove", this.mouseMove, false)
      window.addEventListener("mouseout", this.resetTarget, false)
    }

    this.setupParticles()

    this.update()
  }

  // Update the orb target
  mouseMove = (event: MouseEvent) => {
    this.target = {
      x: event.clientX * this.dpr,
      y: event.clientY * this.dpr,
    }
  }

  // Reset to center when we mouse out
  resetTarget = () => {
    this.target = {
      x: this.width * 0.5,
      y: this.height * 0.5,
    }
  }
  updateDimensions = () => {
    this.width = window.innerWidth * this.dpr
    this.height = window.innerHeight * this.dpr
    this.canvas.width = this.width
    this.canvas.height = this.height
  }
  // Touch Eent
  touchMove = (event: TouchEvent) => {
    if (event.touches.length === 1) {
      event.preventDefault()
    }

    this.target = {
      x: event.touches[0].pageX * this.dpr,
      y: event.touches[0].pageY * this.dpr,
    }
  }

  setupParticles() {
    this.particles = []
    const total = this.count
    const between = PI2 / total
    for (let i = 0; i < total; i++) {
      const max = Math.max(this.width, this.height)
      const angle = (i + 1) * between
      const x = Math.cos(angle) * max + this.width / 2
      const y = Math.sin(angle) * max + this.height / 2

      const particle = new Particle({
        x,
        y,
        radius: this.radius,
        size: this.size,
        angle,
        color: this.color,
      })

      this.particles.push(particle)
    }
  }


  update = () => {
    this.fade()

    const total = this.count

    // Find nearest points for drawing lines to
    for (let i = 0; i < total; i++) {
      const particle = this.particles[i]
      particle.closest = []
      for (let closestIndex = 0; closestIndex < total; closestIndex++) {
        const closest = this.particles[closestIndex]
        const distance = Math.sqrt(Math.pow(particle.position.x - closest.position.x, 2) + Math.pow(particle.position.y - closest.position.y, 2))
        if (distance < this.maxDistance) {
          particle.closest.push({
            x: closest.position.x,
            y: closest.position.y,
            opacity: 1 - (distance / this.maxDistance),
            distance,
          })
        }
      }
      // Draw
      const color = particle.color || this.color
      particle.update(this.target, i)
      this.ctx.globalAlpha = 0.3
      this.ctx.globalCompositeOperation = "lighten"
      this.ctx.fillStyle = "rgb(" + color + ")"
      this.ctx.beginPath()
      this.ctx.arc(particle.position.x, particle.position.y, particle.size, 0, PI2, false)
      this.ctx.closePath()
      this.ctx.fill()

      if (this.maxDistance > 0) {
        this.drawLines(particle, color)
      }
    }
    if(!this.stopped) {
      window.requestAnimationFrame(this.update)
    }
  }

  fade = () => {
    const photoContainer = document.getElementById("photoContainer")
    const images = photoContainer && photoContainer.getElementsByTagName("img")
    const image: HTMLImageElement = images && Array.from(images).find(img => img.complete && img.className.startsWith("AmazonPhotos-module--current"))
    if (images && image) {
      this.ctx.globalCompositeOperation = "source-over"
      this.ctx.globalAlpha = 0.1
      const scale = Math.max(this.canvas.height / image.naturalHeight, this.canvas.width / image.naturalWidth)
      this.ctx.drawImage(image,
        (this.canvas.width - image.naturalWidth * scale) * 0.5,
        (this.canvas.height - image.naturalHeight * scale) * 0.5,
        image.naturalWidth * scale,
        image.naturalHeight * scale,
      )
      this.ctx.globalCompositeOperation = "darken"
    } else {
      this.ctx.globalCompositeOperation = "darken"
      this.ctx.fillStyle = "#222222"
      this.ctx.globalAlpha = 0.1
      this.ctx.fillRect(0, 0, this.width, this.height)
    }
  }

  // Draw connecting lines
  drawLines = function(point: Particle, color: string) {
    let i = -1
    const length = point.closest.length
    this.ctx.globalAlpha = 0.2
    this.ctx.globalCompositeOperation = "screen"
    this.ctx.lineCap = "round"
    for (let i = 0; i < length; i++) {
      this.ctx.lineWidth = (point.size * 2) * point.closest[i].opacity
      this.ctx.strokeStyle = `rgba(${color},${point.closest[i].opacity})`
      this.ctx.beginPath()
      this.ctx.moveTo(point.position.x, point.position.y)
      this.ctx.lineTo(point.closest[i].x, point.closest[i].y)
      this.ctx.stroke()
    }
  }
}
