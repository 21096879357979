import * as React from "react"
import { graphql, Link } from "gatsby"
//import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import AmazonPhotosGallery from "../components/AmazonPhotosGallery"
import styles from '../styles/styles.module.scss'
import Ghost from "../components/experiments/Ghost"

export const fluidImage = graphql`
fragment fluidImage on File {
  childImageSharp {
    fluid(maxWidth: 1000) {
      ...GatsbyImageSharpFluid
    }
  }
}
`

const IndexPage = () => (
  <>
  <AmazonPhotosGallery groupShareToken={'zSyqmgu8TtWr9NFSlUW6MA.VLlLO-OU0XrExDdR6NsQ2m'}/>
    <Ghost/>
    <Layout>
      <SEO title="Home of Jarkko Syjälä" keywords={[`Jarkko`, `Syrjälä`, 'React', 'WebGL', 'Developer']} />
      <div className={styles.textContainer}>
        <h1>Hi people</h1>
        <p>Welcome to my site.</p>
        <p>Now go build something great or <a href="https://linkedin.com/in/syrjala">visit my LinkedIn</a> or maybe taste some <Link to="experiments/gummy-bunnies">gummy bunnies?</Link></p>
      </div>
    </Layout>
  </>
)

export default IndexPage
